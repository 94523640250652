import '@angular/localize/init';

const buildTime = '1715097341365';
const prevBuildTime = localStorage.getItem('buildTime');
if (prevBuildTime) {
	if (buildTime !== prevBuildTime) {
		localStorage.clear();
		localStorage.setItem('buildTime', buildTime);
	}
} else {
	localStorage.clear();
	localStorage.setItem('buildTime', buildTime);
}
import('./bootstrap').catch((err) => console.error(err));
